@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;
@import url("./icomoon/style.css");
@import url("./icomoonV5/style.css");
@layer utilities {
    .tw-placeholder-italic::placeholder {
        font-style: italic;
        color: black;
    }
}


/* BOLD */

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins/Poppins-Black.ttf") format("truetype");
    font-weight: 900;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
    font-weight: 100;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
    font-weight: 200;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: 300;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
    font-weight: 400;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
    /* font-style: normal; */
    font-display: block;
}

/* Work Sans */

@font-face {
    font-family: "Work Sans";
    src: url("../assets/fonts/WorkSans/WorkSans-Black.ttf") format("truetype");
    font-weight: 900;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "Work Sans";
    src: url("../assets/fonts/WorkSans/WorkSans-Bold.ttf") format("truetype");
    font-weight: 700;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "Work Sans";
    src: url("../assets/fonts/WorkSans/WorkSans-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "Work Sans";
    src: url("../assets/fonts/WorkSans/WorkSans-ExtraLight.ttf") format("truetype");
    font-weight: 100;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "Work Sans";
    src: url("../assets/fonts/WorkSans/WorkSans-Light.ttf") format("truetype");
    font-weight: 200;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "Work Sans";
    src: url("../assets/fonts/WorkSans/WorkSans-Regular.ttf") format("truetype");
    font-weight: 300;
    /* font-style: normal; */
    font-display: block;
}
@font-face {
    font-family: "Work Sans";
    src: url("../assets/fonts/WorkSans/WorkSans-Medium.ttf") format("truetype");
    font-weight: 400;
    /* font-style: normal; */
    font-display: block;
}

@font-face {
    font-family: "Work Sans";
    src: url("../assets/fonts/WorkSans/WorkSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
    /* font-style: normal; */
    font-display: block;
}

/*TRADEGOTHIC*/

@font-face {
    font-family: "Trade Gothic LT extended";
    src: url("../assets/fonts/Trade/Trade-Bold.otf") format("truetype");
    font-weight: 700;
    /* font-style: normal; */
    font-display: block;
}




/* PASSWORD */

@font-face {
    font-family: "password";
    font-weight: 600;
    src: url(../assets/fonts/Password/password.ttf) format("truetype");
    font-display: block;
}



body {
    color: #2c2c2c;
    font-family: "Work Sans", sans-serif;
    
}

::-webkit-scrollbar {
    width: 20px;
    height: 20px;
    border-top: 0px solid #eee;
    background-color: transparent;
    border-left: 0px solid #eee;
}

::-webkit-scrollbar-thumb {
    height: 3px;
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: #ccc;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

.Dropdown::-webkit-scrollbar {
    width: 20px;
    height: 20px;
    border-top: 0px solid #eee;
    background-color: transparent;
    border-left: 0px solid #eee;
}

.Dropdown::-webkit-scrollbar-thumb {
    height: 3px;
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: #ccc;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.Dropdown::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

.Dropdown::-webkit-scrollbar-corner {
    background-color: transparent;
}

.tw-text-xxs {
    font-size: 10px;
}

.tw-text-xxxs {
    font-size: 8px;
}

.tw-text-xs {
    font-size: 12px;
}

.tw-text-sm {
    font-size: 14px;
}

.tw-text-base {
    font-size: 16px;
}

.tw-text-lg {
    font-size: 18px;
}

.tw-text-xl {
    font-size: 20px;
}

.tw-text-2xl {
    font-size: 22px;
}

.tw-text-3xl {
    font-size: 24px;
}

.tw-text-4xl {
    font-size: 26px;
}

.tw-text-5xl {
    font-size: 28px;
}

.tw-text-6xl {
    font-size: 30px;
}

.tw-text-7xl {
    font-size: 32px;
}

.tw-text-8xl {
    font-size: 38px;
}

.tw-text-9xl {
    font-size: 50px;
    
}
@media (max-width: 420px) {
    .tw-text-9xl {
      font-size: 40px;
    }
  }

  @media (max-width: 420px) {
    .tw-text-base {
      font-size: 14px;
    }
  }



.docs{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
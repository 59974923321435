@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?pqykzk');
  src:  url('fonts/icomoon.eot?pqykzk#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?pqykzk') format('truetype'),
    url('fonts/icomoon.woff?pqykzk') format('woff'),
    url('fonts/icomoon.svg?pqykzk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ic_cerrar_sesion:before {
  content: "\e91d";
}
.icon-ic_mostrar_contrasea:before {
  content: "\e91e";
}
.icon-ic_ocultar_contrasea:before {
  content: "\e91f";
}
.icon-ic_arrow_small:before {
  content: "\e900";
}
.icon-ic_close_small:before {
  content: "\e901";
}
.icon-ic_dropdown_grande:before {
  content: "\e902";
}
.icon-ic_filtros_grande:before {
  content: "\e903";
}
.icon-ic_less_grande:before {
  content: "\e904";
}
.icon-ic_more_grande:before {
  content: "\e905";
}
.icon-ic_buscar_grande:before {
  content: "\e906";
}
.icon-ic_cerrar_grande:before {
  content: "\e907";
}
.icon-ic_favorito_selected:before {
  content: "\e908";
}
.icon-ic_favorito_empty:before {
  content: "\e909";
}
.icon-ic_bloqueado:before {
  content: "\e90a";
}
.icon-ic_calendario:before {
  content: "\e90b";
}
.icon-ic_checkbox_empty:before {
  content: "\e90c";
}
.icon-ic_checkbox_selected:before {
  content: "\e90d";
}
.icon-ic_download:before {
  content: "\e90e";
}
.icon-ic_footer_mail:before {
  content: "\e90f";
}
.icon-ic_footer_telefono:before {
  content: "\e910";
}
.icon-ic_play_video:before {
  content: "\e911";
}
.icon-ic_seccion_ecommerce:before {
  content: "\e912";
}
.icon-ic_seccion_formaciones:before {
  content: "\e913";
}
.icon-ic_seccion_inicio:before {
  content: "\e914";
}
.icon-ic_seccion_noticias:before {
  content: "\e915";
}
.icon-ic_seccion_novedades:before {
  content: "\e916";
}
.icon-ic_seccion_rrss_tematicas:before {
  content: "\e917";
}
.icon-ic_seccion_rrss:before {
  content: "\e918";
}
.icon-ic_seccion_tarifas:before {
  content: "\e919";
}
.icon-ic_slider:before {
  content: "\e91a";
}
.icon-ic_ubicacion:before {
  content: "\e91b";
}
.icon-ic_usuario:before {
  content: "\e91c";
}
